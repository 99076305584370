@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
    font-family: sans-serif;
    background-color: #fff;
    color: #6d6e75;
    text-transform: capitalize;
}
.container {
    max-width: 70%;
    margin: auto;
}
ul,
li,
a {
    background: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
}
/*....header....*/
header .open-menu {
    display: none;
}
header .container {
    max-width: 80%;
}
header .desktop-view {
    display: flex; /* Display menu in desktop view */
}
header .mobile-view {
    display: none; /* Hide menu in desktop view */
}
@media only screen and (max-width: 768px){
    header .open-menu {
        display: block;
    }
    header .desktop-view {
        display: none;
    }
    header .mobile-view {
        display: block;
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        height: 30vh;
        background-color: #fff;
        z-index: 55;
    }
    header .mobile-view ul {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
}

.instructor .images{
    position: relative;
    transition: 0.5s ease-in-out;
}

.instructor .images:hover img {
    transform: scale(1.2);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    opacity: 1;
}
